.table_review{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef3;
    padding: 40px 0px;
  }
  .table_review_responsive {
    width: 100%;
    /* border: 1px solid #00bcd4; */
    background-color: #efefef33;
    /* padding: 15px; */
    overflow: auto;
    margin-top: 25px;
    /* margin: auto; */
    border-radius: 4px;
   }
   .responsive_review{
    width: 92%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
   }
   .responsive_review .head_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      /* background-color: pink; */
    }
   .responsive_review .head_card .search_patient{
     display: flex;
     align-items: center;
     justify-content: center;
    }
    .responsive_review .head_card .search_patient h6{
      margin-right: 10px;
      margin-top: 5px;
    }
    .responsive_review .head_card .search_patient .search{
      /* background-color: white; */
      padding: 10px;
      border-radius: 10px;
      border: 2px solid #00bcd4;
    }
   .table_tag_review{
    width: 100%;
    font-size: 13px;
    color: #444;
    white-space: nowrap;
    border-collapse: collapse;
   }
  
   .table_tag_review th{
    background-color: #00bcd4;
    color: #fff;
    text-transform: uppercase;
  }
  
   .table_tag_review th{
    padding: 15px;
   }
   .table_tag_review th, .table_tag_review td{
     border: 1px solid #00000017;
     padding: 10px 15px;
   }
   .table_tag_review tr td img{
    display: inline-block;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px #0003;
   }
  .table_tag_review .action_btn{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .table_tag_review .action_btn button{
    text-decoration: none;
    color: #444;
    background-color: #fff;
    border: 1px solid orange;
    display: inline-block;
    padding: 7px 20px;
    font-weight: bold;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
  }
  .table_tag_review .action_btn button:nth-child(1){
    border-color: #26a69a;
  }
  .table_tag_review .action_btn button:nth-child(2){
    border-color: orange;
  }
  .table_tag_review .action_btn button:hover{
    box-shadow: 0px 3px 8px #0003;
  }
  
  .table_tag_review tr{
    background-color: #fff;
    transition: 0.3s ease-in-out;
  }
  .table_tag_review tr:nth-child(odd){
    /* background-color: rgb(238, 238, 238); */
    background-color: #eeeeee;
  }
  .table_tag_review tr:hover{
    filter: drop-shadow(0px 2px 6px #0002);
  }
   
  
  
  
  /* Pagination */
    .pagination{
      display: flex;
      justify-content: end;
      width: 85%;
      margin-top: 10px;
    }
  
    .pagination .pagination_view{
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin: 0px 5px 0px 5px;
      list-style: none;
    }
    .table_review li a{
      display: block;
      text-decoration: none;
      color: #777;
      font-weight: 600px;
      border-radius: 8px;
      border: 1px solid #00bcd4;
    }
    .table_review li.pagination_view:hover a, .table_review li.pagination_view.active a{
      background-color: #00bcd4;
      border: 1px solid #00bcd4;
      color: #fff;
    }
    .table_review li:first-child{
      font-weight: 700;
      font-size: 20px;
    }
    .table_review li:last-child{
      font-weight: 700;
      font-size: 20px;
    }
  /*  */
  
  
  
  
  
  
  
  
    
  
    .patient_first_name{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      flex-wrap: wrap;
    }
    .update_width_both_name_email{
      width: 94%;
      display: flex;
      justify-content: space-between;
    }
    .update_width_name{
      width: 49%;
    }
    .updt_subject_name{
      color: black;
      font-size: 15px;
      margin-bottom: 10px;
    }
    #name{
      width: 100%;
      border: 0.1px solid gray;
      padding: 12px;
      border-radius: 8px;
    }
    #email{
      width: 100%;
      border: 0.1px solid gray;
      padding: 12px;
      border-radius: 8px;
    }
    .onlast_inpu{
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .lastinpu_subject{
      width: 100%;
    }
    #subject{
      width: 100%;
      border: 0.11px solid gray;
      padding: 12px;
      border-radius: 8px;
    }
    .update_buttun{
      margin-top: 25px;
      margin-left: 38px;
      margin-bottom: 20px;
    }
  
    .update_buttun .submit{
      padding: 14px 43px;
      border-radius: 5px;
      margin: 5px;
      background-color: #1a6aff;
      color: white;
      border: 1px solid #1a6aff;
      font-size: medium;
      transition: 0.3s ease-in-out;
    }
  
    .update_buttun #reset{
      background-color: #fda600;
      border-color: #fda600;
    }
  
    .update_buttun #submite:hover{
      background-color: #f1f5fc;
      color: #1a6aff;
    }
  
    .update_buttun #reset:hover{
      background-color: #f1f5fc;
      color: #fda600;
    }
  
    .delete_modal{
      margin-top: 5px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }
    .delete_modal button{
      padding: 12px 43px;
      border-radius: 5px;
      margin-top: 20px;
      background-color: #f60002;
      color: white;
      border: 1px solid #f60002;
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: large;
      transition: 0.3s ease-in-out;
    }
    .delete_modal button:hover{
      background-color: #f1f5fc;
      color: #f60002;
    }
    .delete_modal #delete_loader:hover{
      background-color: #f60002;
      color: white;
    }



    /* Review Card */
.responsive_review .Review_Card{
    width: 100%;
    padding: 0px 15px 10px 15px;
    border-radius: 10px;
}
.responsive_review .Review_Card .loader_review{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.responsive_review .Review_Card .loader_review p{
  margin-top: 10px;
}
.responsive_review .Review_Card .Noreview{
  display: block;
  text-align: center;
  margin-top: 20px;
}
.responsive_review .Review_Card .review_all{
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    border-radius: 15px;
    border: 3px solid whitesmoke;
    display: flex;
}
.responsive_review .Review_Card .review_all img{
    width: 110px;
    height: 110px;
    border-radius: 10px;
    object-fit: cover;
}
.responsive_review .Review_Card .review_all .review_details{
    padding: 0px 20px 0px 20px;
    width: 100%;
}
.responsive_review .Review_Card .review_all .review_details p{
    margin-top: 10px;
    color: gray;
}
.responsive_review .Review_Card .review_all .review_details .bottom_deatil_review{
    display: flex;
    margin-top: 10px;
    text-align: center;
    justify-content: space-between;
}
.responsive_review .Review_Card .review_all .review_details .bottom_deatil_review .star{
    color: #fcb71b;
    margin-left: 10px;
}
.responsive_review .Review_Card .review_all .review_details .bottom_deatil_review .rev_approv{
  padding: 5px 10px 5px 10px;
  background-color: #e91e63;
  border-radius: 8px;
  border: 1px solid whitesmoke;
  color: white;
}


.action_btn{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  .action_btn button{
    text-decoration: none;
    color: #444;
    background-color: #e91e63;
    border: 1px solid orange;
    display: inline-block;
    padding: 15px 30px;
    font-weight: bold;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
  }
  .action_btn button:nth-child(1){
    border-color: #e91e63;
    color: white;
  }
  .action_btn button:nth-child(2){
    border-color: gray;
    background-color: white;
  }
  .action_btn button:hover{
    box-shadow: 0px 3px 8px #0003;
  }
/*  */