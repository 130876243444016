  /* Amount Input */
  .delete_modal .Amount_Main {
      margin-top: 20px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .delete_modal .Amount_Main .label_head {
      width: 97%;
      /* background-color: #514c65; */
  }

  .delete_modal .Amount_Main .label_head .label_div {
      color: black;
      font-size: 15px;
      margin-bottom: 10px;
  }

  .delete_modal .Amount_Main .label_head .label_div #amount {
      width: 100%;
      border: 0.11px solid gray;
      padding: 12px;
      border-radius: 8px;
  }