/* Head Dashboard */
.dashboard{
    width: 100%;
    background-color: #eaeef3;
}
.dashboard .dashboard_head{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 40px 40px 0px 40px;
    flex-wrap: wrap;
    gap: 40px;
}
.dashboard .dashboard_head .first_box{
    display: flex;
    width: 21%;
    height: 110px;
    padding-left: 15px;
    border-radius: 6px;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(90deg,#874daf,#fff); 
}
.dashboard .dashboard_head .first_box h4{
    color: white;
    font-size: 18px;
}
.dashboard .dashboard_head .first_box h3{
    color: white;
    font-size: 25px;
    margin-top: 15px;
}
.dashboard .dashboard_head .first_box .box_right_side{
    width: 68px;
    height: 100%;
    background-color: #874daf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 150px;
    border-bottom-left-radius: 150px;
}
.dashboard .dashboard_head .first_box .box_right_side i{
    color: white;
    font-size: 35px;
}
/*  */






/* Dashboard Body */
.dashboard_body{
    width: 100%;
    padding: 20px;
    display: flex;
    margin-top: 10px;
}
.dashboard_body .first_column{
    display: inline-block;
    width: 60%;
}
.dashboard_body .second_column{
    width: 37%;
    display: inline-block;
}





/* Dashboard Appoinment Table */
  .dashbord_appoint{
   width: 95%;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 20px 30px 20px 20px;
   border-radius: 8px;
   margin-left: 20px;
   box-shadow: 0px 0px 3px rgba(32,32,32,.3);
  }
  .dashbord_appoint .head_in_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dashbord_appoint .head_in_card button{
    text-decoration: none;
    color: white;
    background-color: #874daf;
    border: 1px solid #874daf;
    padding: 8px 18px;
    font-weight: bold;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
  }
  .dashbord_appoint .head_in_card button:hover{
    box-shadow: 0px 3px 8px #0003;
  }
  .dashbord_table {
    width: 100%;
    background-color: #efefef33;
    overflow: auto;
    margin-top: 10px;
    border-radius: 4px;
   }
  .dashbord_table_tag{
   width: 100%;
   font-size: 13px;
   color: #444;
   white-space: nowrap;
   border-collapse: collapse;
  }
 
  .dashbord_table_tag .image_name{
    display: flex;
    align-items: center;
  }
  .dashbord_table_tag .image_name h5{
    margin-left: 10px;
    font-size: 18px;
  }
  
  .dashbord_table_tag th{
   background-color: #874daf;
   color: #fff;
   text-transform: uppercase;
 }
 
  .dashbord_table_tag th{
   padding: 15px;
  }
  .dashbord_table_tag th, .dashbord_table_tag td{
    border: 1px solid #00000017;
    padding: 10px 15px;
  }
  .dashbord_table_tag tr td img{
   display: inline-block;
   width: 60px;
   height: 60px;
   object-fit: cover;
   border-radius: 50%;
   border: 4px solid #fff;
   box-shadow: 0px 2px 6px #0003;
  }

 .dashbord_table_tag tr{
   background-color: #fff;
   transition: 0.3s ease-in-out;
 }
 .dashbord_table_tag tr:nth-child(odd){
   /* background-color: rgb(238, 238, 238); */
   background-color: #eeeeee;
 }
 .dashbord_table_tag tr:hover{
   filter: drop-shadow(0px 2px 6px #0002);
 }
 .dashbord_table_tag .dash_status{
    padding: 10px;
    border: 1px solid blueviolet;
    display: flex;
    justify-content: center;
  }
  /*  */


  /* Patient List */
  .Patient_List{
    width: 95%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 15px 15px 15px;
    border-radius: 8px;
    margin-left: 20px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
  }
  .Patient_List .head_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
  }
  .Patient_List .head_card button{
    text-decoration: none;
    color: white;
    background-color: #874daf;
    border: 1px solid #874daf;
    padding: 6px 14px;
    font-weight:500;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
  }
  .Patient_List .head_card button:hover{
    box-shadow: 0px 3px 8px #0003;
  }

  .Patient_List .center_list {
    width: 100%;
    background-color: #efefef33;
    margin-top: 10px;
    overflow: auto;
   }
  .Patient_List .center_list .pati_table{
   width: 100%;
   font-size: 13px;
   color: #444;
   white-space: nowrap;
   border-collapse: collapse;
  }
  .Patient_List .center_list .pati_table th{
     background-color: whitesmoke;
     color: gray;
     padding: 15px;
  }
  .Patient_List .center_list .pati_table tr td img{
    width: 35px;
    height: 35px;
    border-radius: 4px;
    object-fit: cover;
  }
  .Patient_List .center_list .pati_table tr td{
    padding: 10px;
    border-bottom: 1px solid lightgray;
  }
  .Patient_List .center_list .pati_table tr:hover{
    background-color: whitesmoke;
   }
  .Patient_List .center_list .pati_table tr td .patient_status{
    text-decoration: none;
    color: #44aab4;
    background-color: #ddede6;
    border: 1px solid #ddede6;
    padding: 5px 0px;
    font-weight: bold;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
    text-align: center;
  }
  .Patient_List .center_list .pati_table tr td .patient_status:hover{
    box-shadow: 0px 3px 8px #0003;
  }
/*  */




/* Doctor List */
.doctor_List{
  width: 95%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
  margin-left: 20px;
  box-shadow: 0px 0px 3px rgba(32,32,32,.3);
}
.doctor_List .doctor_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.doctor_List .doctor_top button{
  text-decoration: none;
  color: white;
  background-color: #874daf;
  border: 1px solid #874daf;
  padding: 6px 14px;
  font-weight:500;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
.doctor_List .doctor_top button:hover{
  box-shadow: 0px 3px 8px #0003;
}

.doctor_List .doctor_dash {
  width: 100%;
  background-color: #efefef33;
  margin-top: 10px;
  overflow: auto;
 }
.doctor_List .doctor_dash .doc_table{
 width: 100%;
 font-size: 13px;
 color: #444;
 white-space: nowrap;
 border-collapse: collapse;
}
.doctor_List .doctor_dash .doc_table th{
   background-color: whitesmoke;
   color: gray;
   padding: 15px;
}
.doctor_List .doctor_dash .doc_table tr td img{
  width: 35px;
  height: 35px;
  border-radius: 4px;
  object-fit: cover;
}
.doctor_List .doctor_dash .doc_table tr td{
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.doctor_List .doctor_dash .doc_table tr:hover{
  background-color: whitesmoke;
 }
.doctor_List .doctor_dash .doc_table tr td .dash_status{
  text-decoration: none;
  color: #3e88fd;
  border: 1px solid #3e88fd;
  padding: 5px 5px 5px 5px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
.doctor_List .doctor_dash .doc_table tr td .dash_status:hover{
box-shadow: 0px 3px 8px #0003;
}
.doctor_List .doctor_dash .doc_table tr td .two_icon{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.doctor_List .doctor_dash .doc_table tr td .two_icon i{
  text-decoration: none;
  color: #444;
  background-color: #fff;
  border: 1px solid orange;
  display: inline-block;
  padding: 7px 20px;
  font-weight: bold;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
.doctor_List .doctor_dash .doc_table tr td .two_icon i:nth-child(1){
  border-color: #26a69a;
}
.doctor_List .doctor_dash .doc_table tr td .two_icon i:nth-child(2){
  border-color: orange;
}