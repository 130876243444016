.sidebar {
  /* flex: 1; */
  height: 100vh ;
  /* background-color: rgb(21, 21, 141); */
  background-color: #874daf;
  position: sticky;
  top: 0;
  width: 230px;
  z-index: 999;
  box-shadow: 0 5px 15px rgba(32,32,32,.3);
  /* overflow-y: scroll; */
  overflow: auto;
  white-space: nowrap;
}

.sidebar::-webkit-scrollbar {
  width: 12px;
}
.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #eaeef3; 
}



.sidebarWrapper {
  padding: 20px;
  color: whitesmoke;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
  font-weight: bold;
}

.sidebarList {
  list-style: none;
  padding-left: 5px;
}

.sidebarListItem {
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
  color: #874daf;
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}

.logotop {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 22px;
  margin-bottom: 30px;
  color: white;
}
.logotop:hover {
  color: white;
}